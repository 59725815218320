import React, { useState } from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

import Logo from "../Logo/Logo"
import { NavbarWrapper } from "./Header.styles"
import ApButton from "../../Common/ApButton/ApButton"
import { HEADER_DATA } from "../../../../data/header.data"
import HeaderItem from "./HeaderItem"
import { Link } from "gatsby"
import { LINKS } from "../../../../data/pageLinks.data"
// import useWindowSize from "../../Common/WindowSize/useWindowSize"
import { FiX, FiMenu } from "react-icons/fi"

const Header = ({
  headerAbsolute,
  headerTransparent,
  headerMobile,
  logoDark,
  textlight,
  whiteToggler,
}) => {
  const { Brand, Collapse, Toggle } = Navbar;
  const [crossVisible, setCrossVisibility] = useState(false);

  let getStartedColor = 'primary';
  if (headerMobile) {
    getStartedColor = "greenPrimary";
  }

  return (
    <NavbarWrapper
      headerTransparent={headerTransparent}
      expand="lg"
      headerAbsolute={headerAbsolute}
      className={`${whiteToggler ? "navbar-dark" : null} ${headerMobile} ${crossVisible ? "nav-expanded" : 'nav-condensed'}`}
      textlight={textlight}
      id="site-navbar"

    >
      <Brand to={LINKS.index} as={Link}>
        <Logo variant={logoDark && "dark"} />
      </Brand>
      <Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setCrossVisibility(!crossVisible)}
      >
        {crossVisible ? <FiX /> : <FiMenu />}
      </Toggle>
      <Collapse id="basic-navbar-nav">
        <Nav className="mx-auto ">
          {HEADER_DATA.map((item, idx) => (
            <HeaderItem item={item} key={idx} />
          ))}
        </Nav>
        <Link to={LINKS.contactUs}>
          <ApButton 
            className="border-0 mx-4"
            variant={getStartedColor}
          >
            Get Started
          </ApButton>
        </Link>
      </Collapse>
    </NavbarWrapper>
  )
}

export default Header
