import styled from "styled-components"

import Navbar from "react-bootstrap/Navbar"

import { colors, fonts } from "../../../../utils/styles"

const { primaryBlue, secondaryBlue2 } = colors

export const NavbarWrapper = styled(Navbar)`
  z-index: 999;
  width: 100vw;
  min-width: 320px;
  @media (min-width: 992px) {
    position: ${(props) => (props.headerAbsolute ? "absolute" : "static")};
  }
  @media (max-width: 992px) {
    background-color: ${(props) =>
      props.headerTransparent ? primaryBlue : "white"} ;
  }

  &.blueGradient{
    @media (max-width: 992px) {
      background-color: transparent;
      background: linear-gradient(90deg,#84E7F0 0%,#78AAEE 100%),#144056;
      background-blend-mode: overlay,normal;
    }
  }
  &.ghostWhite{
    @media (max-width: 992px) {
      background-color: #F7F9FC;
    }
    .navbar-toggler{
      color: ${secondaryBlue2};
    }
  }
  &.bgWhite{
    @media (max-width: 992px) {
      background-color: #FFFFFF;
    }
    .navbar-toggler{
      color: ${secondaryBlue2};
    }
  }
  &.stay-absolute{
    position: absolute;
    &.toggler-green{
      .navbar-toggler{
        color: ${secondaryBlue2};
      }
    }
    &.nav-condensed{
      background-color: transparent;
    }

    &.secondPrimary{
      background-color: #144056;
      .navbar-toggler{
        color: #FFF;
      }
      &.nav-condensed{
        background-color: transparent;
      }
    }
  }

  .dropdown-menu {
    border: none;
  }
  a {
    font-weight: 600;
    color: ${(props) => (props.textlight ? "#ffffff" : primaryBlue)} !important;
  }
  .nav-item {
    margin-right: 20px;
  }
  .dropdown-item-heading {
    padding: 0.25rem 1.5rem;
    color: ${primaryBlue};
    svg {
      width: 16px;
      margin-right: 5px;
    }
  }
  .dropdown-item {
    &.active,
    &:active {
      background-color: transparent !important;
    }
    p {
      color: ${secondaryBlue2};
    }
    span {
      color: #3f4041;
      font-family: ${fonts.poppins};
      font-weight: 100;
      font-size: x-small;
      @media (max-width: 400) {
        white-space: normal;
      }
    }
  }
  .dropdown-toggle {
    &:after {
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: ${secondaryBlue2};
      border-width: 0px 1px 1px 0px;
      transform: rotate(45deg);
      transition: border-width 150ms ease-in-out;
      margin-left: 10px;
    }
  }
  .navbar-toggler {
    border: 0px;
    &:active {
      outline: none;
      border: none;
    }
    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
  .basic-nav-dropdown {
    position: relative;
    a[aria-expanded="true"]::after {
      transform: translateY(4px) rotate(-135deg);
      transition: border-with 0s;
    }
    a[aria-expanded="true"]::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      height: 1px;
      width: 100%;
      transform: translateY(-23px) scale(1.1);
      transition: border-with 0.2s;
      @media (min-width: 992px) {
        border: 1px ${secondaryBlue2} solid;
      }
    }
  }

  .row{
    margin: 0;
  }
`
