import React from "react"
import { NavDropdown, Col, Row } from "react-bootstrap"
import DropDownItemImage from "./DropDownItemImage"
import { Link } from "gatsby"

const getLinkProps = (url) =>
  url.startsWith("/") ? { to: url, as: Link } : { href: url , target:"_blank"}

const HeaderDropdownItem = ({ item }) => {
  const { title, links, imageVariant } = item
  return (
    <Row>
      <Col className="px-0">
      {title && (
        <Row className="dropdown-item-heading " >
          <Col xs={1} className="d-none d-lg-block">
          <DropDownItemImage variant={imageVariant} />
          </Col>
          <Col className="pl-2">
          <span>{title}</span>
          </Col>
        </Row>
      )}
      {links.map((link) => {
        const className = link.imageVariant ? "d-flex align-items-center" : ""
        const hrefProps = getLinkProps(link.url)
        return (
          <NavDropdown.Item {...hrefProps} className={className}>
              {link.imageVariant && (
              <DropDownItemImage variant={link.imageVariant} />
            )}
            <div className={`${link.imageVariant ? "pl-3" : ""} link-subtitle`}>
              <p className="mb-0">{link.title}</p>
              <span>{link.description}</span>
            </div>
          </NavDropdown.Item>
        )
      })}
      </Col>
    </Row>
  )
}

export default HeaderDropdownItem
